import { Backdrop, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import LanguageConfig from "./components/languageConfig";
import GetLanguageMapList from "hooks/LanguageList/GetLanguageList";
import ClientConfig from "./components/clientConfig";
import AllowMarkets from "./components/allowMarkets";
import AllowBuyers from "./components/allowBuyers";
import { enqueueSnackbar } from "notistack";
const MarketsAndClients = () => {
  let { language, loading } = GetLanguageMapList();
  const [allClients, setAllClients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientLangList, setClientLangList] = useState([]);
  const [buyerList, setBuyerList] = useState([]);

  const GetClientList = async () => {
    setIsLoading(true);
    try {
      let clientlist = await TLinkApi.get("/clients/list");
      setAllClients(clientlist.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    GetClientList();
  }, []);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const GetClientLanguageList = async () => {
    setIsLoading(true);
    try {
      let langList = await TLinkApi.get(`/clients/${selectedClient}/languages`);
      if (langList?.data?.languages) {
        setClientLangList(
          langList?.data?.languages?.map((val) => {
            return {
              id: Number(val.languageId),
              name: val.languageName,
            };
          })
        );
      } else {
        setClientLangList([]);
      }

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setClientLangList([]);
      enqueueSnackbar(e?.response?.data?.message, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedClient) {
      GetClientLanguageList();
    }
  }, [selectedClient]);

  const GetClientBuyerList = async () => {
    setIsLoading(true);
    try {
      let buyerList = await TLinkApi.get(`/clients/${selectedClient}/buyers`);
      if (buyerList?.data) {
        setBuyerList(
          buyerList?.data?.map((val) => {
            return {
              id: Number(val.buyerId),
              name: val.buyerName,
            };
          })
        );
      } else {
        setBuyerList([]);
      }

      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setBuyerList([]);
      enqueueSnackbar(e?.response?.data?.message, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedClient) {
      GetClientBuyerList();
    }
  }, [selectedClient]);

  return (
    <div>
      {isLoading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      <Typography color="dark" fontWeight="600" mb="1rem">
        Markets & Clients
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3} sx={{ paddingLeft: "0px !important" }}>
          <LanguageConfig
            language={language}
            setIsLoading={setIsLoading}
            GetLanguageMapList={GetLanguageMapList}
          />
        </Grid>
        <Grid item xs={3} sx={{ paddingLeft: "0px !important" }}>
          <ClientConfig
            allClients={allClients}
            setIsLoading={setIsLoading}
            setSelectedClient={setSelectedClient}
            selectedClient={selectedClient}
          />
        </Grid>
        <Grid item xs={3} sx={{ paddingLeft: "0px !important" }}>
          <AllowMarkets
            langList={clientLangList}
            setIsLoading={setIsLoading}
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
          />
        </Grid>
        <Grid item xs={3} sx={{ paddingLeft: "0px !important" }}>
          <AllowBuyers
            buyerList={buyerList}
            setIsLoading={setIsLoading}
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default MarketsAndClients;
