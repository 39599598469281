import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import NotificationItem from "comps/Items/NotificationItem";
import PersonIcon from "@mui/icons-material/Person";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import { navbarContainer, navbarRow, navbarMobileMenu } from "comps/Navbars/DashboardNavbar/styles";
import { useMaterialUIController, setTransparentNavbar, setMiniSidenav } from "context";
import {
  Backdrop,
  CircularProgress,
  InputAdornment,
  MenuItem,
  Modal,
  Stack,
  TableBody,
  TableCell,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "../../../assets/images/search.svg";
import TLinkApi from "services/tlink.api";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import CrossIcon from "assets/images/cross.svg";
import Search from "assets/images/SearchIcon.svg";
import {
  Avatars,
  BorderTable,
  ContactType,
  Content,
  NameTag,
  PageWrapper,
  PrimaryBox,
  ScrollableTableContainer,
  StyledModalListClientName,
  StyledModalListName,
  StyledNewTab,
  StyledTableRow,
} from "./header.style";
import SameTabViewIcon from "assets/images/SameTabViewIcon.svg";
import NewTabViewIcon from "assets/images/NewTabViewIcon.svg";
import CopyIcon from "assets/images/copy.svg";
import CardCircleIcon from "assets/images/CardCircleIcon.svg";
import TabsComponent from "components/TabsGlobalSearch/tabs.component";
import { TabPanel } from "components/TabsGlobalSearch/tabs.component";
import { useSnackbar } from "notistack";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const [search, setSearch] = useState("");
  // const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [searchResults, setSearchResults] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [showNoRecordsMessage, setShowNoRecordsMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  let navigate = useNavigate();
  let { enqueueSnackbar } = useSnackbar();
  const ref = useRef();
  const timerRef = useRef(null);
  useEffect(() => {
    if (openModal) {
      document.addEventListener(
        "click",
        (e) => {
          if (ref.current && !ref.current.contains(e.target)) {
            setOpenModal(false);
            setSearch("");
            setSearchResults(null);
          }
        },
        true
      );
    }
  }, [openModal]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutUser = () => {
    // Auth.remove("auth");
    localStorage.removeItem("auth");
    setTimeout(function () {
      navigate("/login");
    }, 1000);
  };
  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleCloseMenu = () => setOpenMenu(false);

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const fetchSearchResults = async () => {
    setLoading(true);
    try {
      const response = await TLinkApi.get(`/global/?search=${search}`);
      setSearchResults(response.data.search);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const fetchUserDetails = async () => {
    try {
      const response = await TLinkApi.get(`/users/current`);
      setUserDetails(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  function getInitials(str) {
    let names = str.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

  function stringToHslColor(str, s, l) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;
    return "hsl(" + h + ", " + s + "%, " + l + "%)";
  }

  function copy(text) {
    navigator.clipboard.writeText(text);
    enqueueSnackbar("Copy Successfully!", {
      variant: "success",
    });
  }

  const BackGroundColor = (statusId) => {
    switch (statusId) {
      case 1:
        return "#FF9999";
      case 2:
        return "#81b03c";
      case 3:
        return "#fcae3a";
      case 4:
        return "#c29191";
      case 5:
        return "#ADD8E6";
      case 6:
        return "#4682B4";
      case 7:
        return "#D3D3D3";
      case 8:
        return "#A9A9A9";
      case 9:
        return "#696969";
      default:
        return "#FFFFFF";
    }
  };

  const categories = [
    {
      icon: <Icon fontSize="medium">folder_open_icon</Icon>,
      label: `Projects \u2003 ${searchResults?.projects?.length || 0}`,
      data:
        searchResults?.projects?.map((item) => ({
          ...item,
          source: "Projects",
        })) || [],
    },
    {
      icon: <Icon fontSize="medium">flare_icon</Icon>,
      label: `Clients \u2003 ${searchResults?.clients?.length || 0}`,
      data:
        searchResults?.clients?.map((item) => ({
          ...item,
          source: "Clients",
        })) || [],
    },
    {
      icon: <Icon fontSize="medium">storefrontoutlinedicon</Icon>,
      label: `Vendors \u2003 ${searchResults?.vendors?.length || 0}`,
      data:
        searchResults?.vendors?.map((item) => ({
          ...item,
          source: "Vendors",
        })) || [],
    },
    {
      icon: <Icon fontSize="medium">ballot_icon</Icon>,
      label: `Surveys \u2003 ${searchResults?.surveys?.length || 0}`,
      data:
        searchResults?.surveys?.map((item) => ({
          ...item,
          source: "Surveys",
        })) || [],
    },
  ];

  const allLength =
    (searchResults?.clients?.length || 0) +
    (searchResults?.projects?.length || 0) +
    (searchResults?.vendors?.length || 0) +
    (searchResults?.surveys?.length || 0);

  categories.unshift({
    icon: <img src="" />,
    label: `All \u2003 ${allLength}`,
    data: [].concat(
      categories[0]?.data || [],
      categories[1]?.data || [],
      categories[2]?.data || [],
      categories[3]?.data || []
    ),
  });

  const tabPanels = categories
    .map((category, tabIndex) => {
      if (!category?.data?.length) return null;

      const table = (
        <ScrollableTableContainer style={{ height: `calc(100vh - 500px)` }}>
          <BorderTable>
            <TableBody style={{ padding: "0px" }}>
              {category?.data?.map((user, index) => {
                function isProjectGlobal(obj) {
                  return obj.source === "Projects";
                }

                function isClientGlobal(obj) {
                  return obj.source === "Clients";
                }
                function isVendorGlobal(obj) {
                  return obj.source === "Vendors";
                }

                function isSurveyGlobal(obj) {
                  return obj.source === "Surveys";
                }

                let name;
                let id = 0;
                let rowStatus;
                let rowStatusid;
                let client_name;
                let project_services = [];

                if (isProjectGlobal(user)) {
                  name = user?.name;
                  id = user?.id;
                  rowStatus = user.projectStatusName;
                  rowStatusid = user.projectStatusId;
                  client_name = user.clientName;
                  // project_services = "user?.project_services";
                } else if (isClientGlobal(user)) {
                  name = user.name;
                  id = user.id;
                } else if (isVendorGlobal(user)) {
                  name = user.name;
                  id = user.id;
                } else if (isSurveyGlobal(user)) {
                  name = user.name;
                  id = user.id;
                }
                let urls = user?.source?.toLowerCase();
                return (
                  <StyledTableRow key={index}>
                    <TableCell
                      style={{
                        border: "none",
                        borderBottom: "1px solid #DFE3E8",
                        display: "flex",
                      }}
                    >
                      <Box
                        style={{
                          border: "none",
                          padding: "0.2rem 0rem 0.2rem 0.8rem",
                          display: "flex",
                          minWidth: "50%",
                        }}
                      >
                        <Avatars
                          style={{
                            width: "2.5rem",
                            height: "2.5rem",
                            margin: "0px",
                          }}
                        >
                          <NameTag style={{ fontSize: "1rem" }} variant="h6">{`${name
                            ?.charAt(0)
                            ?.toUpperCase()}${name?.charAt(0)?.toUpperCase()} `}</NameTag>
                        </Avatars>
                        <Box style={{ marginLeft: "1rem" }}>
                          <Box style={{ display: "flex", marginBottom: "4px" }}>
                            <StyledModalListName>
                              {(name ? name.slice(0, 30) : "") +
                                (name && name.length > 30 ? "..." : "") +
                                " / " +
                                id}
                            </StyledModalListName>
                            {rowStatusid && (
                              <PrimaryBox
                                display="flex"
                                justifyContent="center"
                                style={{
                                  background: `${BackGroundColor(rowStatusid)}`,
                                  marginLeft: "1rem",
                                }}
                              >
                                <ContactType style={{ color: `white` }}>{rowStatus}</ContactType>
                              </PrimaryBox>
                            )}
                          </Box>
                          <Box style={{ display: "flex" }}>
                            <StyledModalListClientName>
                              {client_name ? (
                                <>
                                  {client_name.substring(0, 30).charAt(0).toUpperCase() +
                                    client_name.substring(0, 30).slice(1)}
                                  <img
                                    src={CardCircleIcon}
                                    style={{ margin: "0rem 0.2rem 0.1rem 0.2rem" }}
                                  />
                                </>
                              ) : (
                                ""
                              )}
                              {user?.source}
                            </StyledModalListClientName>{" "}
                          </Box>
                        </Box>
                      </Box>
                      <Box style={{ minWidth: "30%" }}></Box>
                      <Box style={{ minWidth: "20%" }}>
                        <Box
                          style={{
                            display: "flex",
                            marginTop: "0.9rem",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            paddingRight: "1rem",
                          }}
                        >
                          <IconButton
                            sx={{
                              padding: "4px",
                              margin: "0rem 0.2rem 0.1rem 0.2rem",
                            }}
                          >
                            <img
                              src={SameTabViewIcon}
                              height={24}
                              width={24}
                              onClick={() => {
                                navigate(`/${urls}/${id}`);
                                setOpenModal(false);
                              }}
                              style={{
                                cursor: "pointer",
                              }}
                            />
                          </IconButton>
                          <IconButton
                            sx={{ padding: "2px", margin: "0rem 0.3rem 0.1rem 0.2rem" }}
                            onClick={() => {
                              copy(`${id}`);
                            }}
                          >
                            <img src={CopyIcon} height={24} width={24} />
                          </IconButton>
                          <StyledNewTab
                            style={{ cursor: "pointer" }}
                            onClick={() => window.open(`/${urls}/${id}`, "_blank")}
                          >
                            <img
                              src={NewTabViewIcon}
                              height={24}
                              width={24}
                              style={{ margin: "0rem 0.5rem -0.2rem 0.2rem" }}
                            />
                            <span style={{ margin: "-0.9rem 0.0rem 0.0rem 0.0rem" }}>New Tab</span>
                          </StyledNewTab>
                        </Box>
                      </Box>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </BorderTable>
        </ScrollableTableContainer>
      );

      return (
        <TabPanel key={tabIndex} index={tabIndex} value={tabIndex}>
          {table}
        </TabPanel>
      );
    })
    .filter(Boolean);

  const labels = categories
    .filter((category) => category?.data?.length)
    .map((category) => ({
      label: category.label,
      isDisabled: false,
      icon: category.icon,
    }));

  // let searchTimeoutId = null;

  useEffect(() => {
    let timer;
    if (allLength <= 0 && !loading && search?.length > 2) {
      timer = setTimeout(() => {
        setShowNoRecordsMessage(true);
      }, 1000);
    } else {
      setShowNoRecordsMessage(false);
    }

    return () => clearTimeout(timer);
  }, [allLength, loading, search]);

  const debouncedSearchData  = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    // if (!timerRef.current) {
    //   fetchSearchResults();
    // }
    timerRef.current = setTimeout(() => {
      fetchSearchResults();
      timerRef.current = null;
    }, 500);
  }
 

  useEffect(() => {
    // if (searchTimeoutId !== null) {
    //   clearTimeout(searchTimeoutId);
    // }
    // if (search?.length > 0) {
    //   searchTimeoutId = setTimeout(() => {
    //     fetchSearchResults();
    //   }, 500);
    // }
      debouncedSearchData()
  }, [search]);

  return (
    <AppBar
      style={{ zIndex: 5, padding: "0px", width: "100%", borderRadius: "0px" }}
      color="inherit"
      sx={{ borderBottom: "1px solid #E4E4E4", position: "sticky", left: "50px" }}
    >
      <Toolbar
        sx={(theme) => navbarContainer(theme)}
        style={{ padding: "0px 0px 0px 10px", borderRadius: "0px" }}
      >
        <Modal
          open={openModal}
          // onClose={handleClose}
        >
          <PageWrapper alignItems="flex-start">
            <Content
              ref={ref}
              style={{
                padding: search.length === 0 ? 0 : "1.5rem",
                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.6)",
              }}
            >
              <TextField
                autoFocus
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <img
                      src={Search}
                      width={32}
                      height={32}
                      style={{ margin: "0.0rem 0.5rem 0rem 0.5rem" }}
                    />
                  ),
                  endAdornment: (
                    <img
                      src={CrossIcon}
                      width={20}
                      height={20}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOpenModal(false);
                        setSearch("");
                        setSearchResults(null);
                      }}
                    />
                  ),
                }}
                style={{
                  width: search.length > 0 ? "70vw" : "50vw",
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    color: "inherit",
                    border: "none",
                    "&.Mui-focused": { border: "none" },
                    "&:hover": { border: "none" },
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  background: "#F4F6F8",
                  borderRadius: "0.5rem",
                  "& .MuiInputBase-input": {
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "140%",
                    color: "#005249",
                  },
                }}
              />
              <Box
                style={{
                  display: search.length > 0 ? "flex" : "none",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                {allLength > 0 && <TabsComponent tabpanels={tabPanels} labels={labels} />}
                {showNoRecordsMessage && (
                  <Typography style={{ marginTop: "1rem", fontSize: "1rem", fontWeight: 600 }}>
                    No Records Found
                  </Typography>
                )}

                <Backdrop
                  sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={loading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </Box>
            </Content>
          </PageWrapper>
        </Modal>

        <TextField
          fullWidth
          autoComplete="off"
          placeholder="Type to search"
          sx={{
            width: "600px",
            border: "none",
            "& fieldset": { border: "none" },
          }}
          onClick={() => {
            setOpenModal(true);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={SearchIcon} />
              </InputAdornment>
            ),
          }}
        />

        {/* {isMini ? null : ( */}
        <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
          <MDBox
            color={light ? "white" : "inherit"}
            sx={{ display: "flex", justifyContent: "space-between", borderRadius: "0px" }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                height: "15px",
                mr: "16px",
                cursor: "pointer",
              }}
              onClick={(event) => handleClick(event)}
            >
              <div
                style={{
                  fontFamily: "Arial, Helvetica, sans-serif",
                  width: "2rem",
                  height: "2rem",
                  borderRadius: "50%",
                  background: stringToHslColor(
                    getInitials(`${userDetails?.firstName} ${userDetails?.lastName}`),
                    30,
                    50
                  ),
                  fontSize: "1rem",
                  color: "#fff",
                  textAlign: "center",
                  lineHeight: "2rem",
                }}
              >
                {getInitials(`${userDetails?.firstName} ${userDetails?.lastName}`)}
              </div>
            </Box>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: 0,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              onClose={handleClose}
            >
              <Box
                sx={{
                  display: "flex",
                  direction: "column",
                  gap: "1rem",
                  height: "85px",
                  width: "250px",
                  justifyContent: "left",
                  ml: "5px",
                }}
              >
                <Stack spacing={1} direction="row" alignItems="center">
                  <div
                    style={{
                      fontFamily: "Arial, Helvetica, sans-serif",
                      width: "3rem",
                      height: "3rem",
                      borderRadius: "50%",
                      background: stringToHslColor(
                        getInitials(`${userDetails?.firstName} ${userDetails?.lastName}`),
                        30,
                        50
                      ),
                      // margin: "0.5rem",
                      fontSize: "1.5rem",
                      color: "#fff",
                      textAlign: "center",
                      alignContent: "center",
                      lineHeight: "3rem",
                      verticalAlign: "center",
                    }}
                  >
                    {getInitials(`${userDetails?.firstName} ${userDetails?.lastName}`)}
                  </div>
                </Stack>
                <Stack alignItems="left" display="block">
                  <Stack spacing={1} direction="row" alignItems="center">
                    <PersonIcon />
                    <Typography
                      sx={{ fontSize: "15px", alignContent: "left" }}
                    >{`${userDetails?.firstName} ${userDetails?.lastName}`}</Typography>
                  </Stack>

                  <Stack spacing={1} direction="row" alignItems="center">
                    <MailOutlineIcon />
                    <Typography
                      sx={{ fontSize: "15px", alignContent: "left" }}
                    >{`${userDetails?.email}`}</Typography>
                  </Stack>

                  <Stack spacing={1} direction="row" alignItems="center">
                    <ManageAccountsIcon />
                    <Typography
                      sx={{ fontSize: "15px", alignContent: "left" }}
                    >{`${userDetails?.roleName}`}</Typography>
                  </Stack>
                </Stack>
              </Box>

              <MenuItem
                sx={{
                  fontSize: "15px",
                  mt: "10px",
                  mb: "0px",
                  justifyContent: "center",
                  alignItems: "center",
                  bgcolor: light,
                  "border-radius": 0,
                }}
                onClick={() => {
                  logoutUser();
                  handleClose();
                }}
              >
                Logout
              </MenuItem>
            </Menu>
            <IconButton
              size="small"
              disableRipple
              color="inherit"
              sx={navbarMobileMenu}
              onClick={handleMiniSidenav}
            >
              <Icon sx={iconsStyle} fontSize="medium">
                {miniSidenav ? "menu_open" : "menu"}
              </Icon>
            </IconButton>
            {renderMenu()}
          </MDBox>
        </MDBox>
        {/* )} */}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
