import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetSurveyBasicList = (projectId, filtersLength) => {
  const [surveys, setSurveys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [onload, setOnload] = useState(true);
  const [filter, setFilter] = useState(projectId ? [] : ["2"]);
  const [clientFilter, setClientFilter] = useState([]);
  const [totalLength, setTotalLength] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("id");
  const [languageFilter, setLanguageFilter] = useState([]);
  const [projectMangerFilter, setProjectMangerFilter] = useState([]);
  const [manual, setManual] = useState(true);

  const getSurveys = async () => {
    if (search === "") {
      setIsLoading(true);
    }

    try {
      let data = await TLinkApi.get(
        `/survey?search=${search}&filter=${
          filter.length === 0 ? -1 : filtersLength.statusLength === filter.length ? -1 : filter
        }&page=${
          page + 1
        }&limit=${entriesPerPage}&sortOrder=${sortOrder}&sortBy=${sortBy}&languageFilter=${
          languageFilter.length === 0
            ? -1
            : filtersLength.languageLength === languageFilter.length
            ? -1
            : languageFilter
        }&clientFilter=${
          clientFilter.length === 0
            ? -1
            : filtersLength.clientLength === clientFilter.length
            ? -1
            : clientFilter
        }&projectManagerFilter=${
          projectMangerFilter.length === 0
            ? -1
            : filtersLength.projectLength === projectMangerFilter.length
            ? -1
            : projectMangerFilter
        }&isProgrammaticFilter=${projectId ? "" : !manual}&projectId=${projectId}`
      );
      setSurveys(data.data.surveyData);
      setTotalLength(data.data.totalLenght);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (onload) {
      getSurveys();
    }
  }, [filter, search, sortBy, sortOrder]);

  useEffect(() => {
    if (!onload) {
      getSurveys();
    }
    // eslint-disable-next-line
  }, [page, search, entriesPerPage, sortBy, sortOrder, manual]);

  return {
    surveys,
    getSurveys,
    isLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setFilter,
    clientFilter,
    setClientFilter,
    filter,
    setEntriesPerPage,
    entriesPerPage,
    setSortOrder,
    setSortBy,
    sortBy,
    sortOrder,
    languageFilter,
    setLanguageFilter,
    projectMangerFilter,
    setProjectMangerFilter,
    onload,
    setOnload,
    manual,
    setManual,
  };
};

export default GetSurveyBasicList;
