import Icon from "@mui/material/Icon";
import PublicIcon from '@mui/icons-material/Public';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="medium">grid_view_icon</Icon>,
    route: "/dashboard",
  },

  {
    type: "title",
    name: "Projects",
    key: "projects",
    icon: <Icon fontSize="medium"> folder_open_icon</Icon>,
    collapse: [
      {
        name: "Projects",
        key: "projects",
        route: "/projects",
        icon: "assignment_icon",
      },
      {
        name: "Surveys",
        key: "surveys",
        route: "/surveys",
        icon: "ballot_icon",
      },
    ],
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    icon: <Icon fontSize="medium">bubble_chart_outlined_icon</Icon>,
    route: "/reports",
  },
  {
    type: "title",
    name: "Admin",
    key: "admin",
    icon: <Icon fontSize="small">admin_panel_settings_icon</Icon>,
    collapse: [
      {
        name: "Vendors",
        key: "vendors",
        route: "/vendors",
        icon: "storefrontoutlinedicon",
      },
      {
        name: "Clients",
        key: "clients",
        route: "/clients",
        icon: "flare_icon",
      },
      {
        name: "Users",
        key: "users",
        route: "/users",
        icon: "ballot_icon",
      },
      {
        name: "Permissions",
        key: "permissions",
        route: "/permissions",
        icon: "assignment_icon",
      },
      {
        name: "Priority",
        key: "priority",
        route: "/priority",
        icon: "low_priority_icon",
      },
      {
        name: "Qualifications",
        key: "qualification",
        route: "/qualification",
        icon: "psychology_alt_outlined_icon",
      },
    ],
  },
  {
    type: "collapse",
    name: "GlobalReconciliation",
    key: "globalReconciliation",
    icon: <Icon fontSize="medium">language_icon</Icon>,
    route: "/globalReconciliation",
  },
  {
    type: "collapse",
    name: "SecurityTemplate",
    key: "securityTemplate",
    icon: <Icon fontSize="medium">lock_person_icon</Icon>,
    route: "/securityTemplate",
  },
];

export default routes; 