import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";

const GetProjectsList = (filtersLength) => {
  const [projects, setProjects] = useState([]);
  const [projectMap, setProjectMap] = useState();
  const [page, setPage] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(50);
  const [projectStatusFilter, setProjecStatusFilter] = useState(["2"]);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [clientFilter, setClientFilter] = useState([]);
  const [projectManagerFilter, setProjectManagerFilter] = useState([]);
  const [onload, setOnload] = useState(true);
  const [manual, setManual] = useState(true);

  const getProjects = async () => {
    if (search === "") {
      setIsLoading(true);
    }
    try {
      let data = await TLinkApi.get(
        `/projects?search=${search}&page=${
          page + 1
        }&limit=${entriesPerPage}&sortOrder=${sortOrder}&sortBy=${sortBy}&status=${
          projectStatusFilter.length == 0
            ? -1
            : filtersLength.statusLength === projectStatusFilter.length
            ? -1
            : projectStatusFilter
        }&clientFilter=${
          clientFilter.length === 0
            ? -1
            : filtersLength.clientLength === clientFilter.length
            ? -1
            : clientFilter
        }&projectManagerFilter=${
          projectManagerFilter.length === 0
            ? -1
            : filtersLength.projectLength === projectManagerFilter.length
            ? -1
            : projectManagerFilter
        }&isProgrammaticFilter=${!manual}`
      );
      setProjects(data.data.projectData);
      setTotalLength(data.data.totalLenght);
      setIsLoading(false);
      let projectsMap = [];
      data?.data?.projectData?.map((value) => {
        projectsMap[value.id] = value.name;
        return true;
      });
      setProjectMap(projectsMap);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (onload && projectStatusFilter.length > 0) {
      getProjects();
    }
  }, [projectStatusFilter, search, sortBy, sortOrder]);

  useEffect(() => {
    if (!onload) {
      getProjects();
    }
    // eslint-disable-next-line
  }, [page, search, entriesPerPage, sortBy, sortOrder, manual]);

  return {
    projects,
    getProjects,
    projectMap,
    isLoading,
    totalLength,
    setPage,
    page,
    setSearch,
    search,
    setEntriesPerPage,
    entriesPerPage,
    setProjecStatusFilter,
    projectStatusFilter,
    setSortOrder,
    setSortBy,
    sortBy,
    setOnload,
    sortOrder,
    clientFilter,
    setClientFilter,
    projectManagerFilter,
    setProjectManagerFilter,
    manual,
    setManual,
  };
};

export default GetProjectsList;
