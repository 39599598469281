import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import {
  Backdrop,
  CircularProgress,
  FormControl,
  FormHelperText,
  Icon,
  IconButton,
  MenuItem,
  Select,
  Typography,
  Stack,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import colors from "assets/theme/base/colors";

function UserBasicDetails() {
  const { formDetailsColor } = colors;

  const navigate = useNavigate();
  const { user, setUser, setIsEdit, isEdit, getUser, isLoading, setIsLoading } = useOutletContext();
  let { enqueueSnackbar } = useSnackbar();
  const [roles, setRoles] = useState([]);
  const [status, setStatus] = useState([]);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    register,
  } = useForm({
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
      roleId: "",
      isActive: false,
      userStatusId: 3,
      description: "",
    },
  });

  useEffect(() => {
    if (user) {
      setValue("email", user.email);
      setValue("firstName", user.firstName);
      setValue("isActive", user.isActive);
      setValue("lastName", user.lastName);
      setValue("roleId", user.roleId);
      setValue("lastName", user.lastName);
      setValue("userStatusId", user.userStatusId);
      setValue("description", user.description);
    }
  }, [user]);

  const { id } = useParams();

  useEffect(() => {
    GetRoles();
    GetStatus();
  }, [id]);

  const GetRoles = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.get(`/masters/role`);
      setRoles(result.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const GetStatus = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.get(`/masters/userstatus`);
      setStatus(result.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const label = (value, hide) => {
    return (
      <MDBox fontWeight="400" fontSize="12px" color="rgba(0, 0, 0, 0.85)" pb={0.5}>
        {value} {hide ? "" : <span>* &nbsp;</span>}
      </MDBox>
    );
  };

  let selectStyle = {
    height: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  const save = async (data) => {
    setIsLoading(true);
    try {
      if (isEdit.edit === true) {
        let result = await TLinkApi.put(`/users/${user.id}`, data);
        setIsEdit({
          disabled: false,
          edit: true,
        });
        setIsLoading(false);
        setUser({});

        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        navigate(`/users`);
      } else {
        let result = await TLinkApi.post("/users", data);
        setIsLoading(false);
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
        setIsEdit({
          disabled: false,
          edit: true,
        });
        setUser({});

        navigate(`/users`);
      }
    } catch (e) {
      setIsLoading(false);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <form onSubmit={handleSubmit(save)} noValidate>
        <MDBox p={2} px={20} style={{ width: "100%" }} mt={2}>
          <Stack
            justifyContent={"space-between"}
            alignItems="flex-end"
            sx={{ width: "100%" }}
            direction={isEdit.edit ? "row" : ""}
          >
            <Stack direction="row" spacing={2} mb={2}>
              <Typography fontWeight="600">Basic Details</Typography>
            </Stack>
            <MDBox display="flex" justifyContent="flex-end" mb={2}>
              {isEdit.disabled !== false && (
                <IconButton
                  color="dark"
                  size="small"
                  onClick={() => {
                    setIsEdit({
                      disabled: false,
                      edit: true,
                    });
                    navigate(`/users/${id}/ `);
                  }}
                >
                  <Icon>border_color_icon</Icon>
                </IconButton>
              )}
            </MDBox>
            {isEdit.disabled !== true && (
              <MDBox display="flex" justifyContent="flex-end" alignItems="center" gap="10px">
                <MDButton
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    getUser();
                    if (window.location.pathname === "/users/new") {
                      navigate(`/users`);
                    } else {
                      setIsEdit({
                        disabled: true,
                        edit: false,
                      });
                    }
                  }}
                >
                  &nbsp;cancel
                </MDButton>
                <MDButton variant="gradient" color="info" type="submit" size="small">
                  {window.location.pathname === "/users/new" ? "Invite" : "Save"}
                </MDButton>
              </MDBox>
            )}
          </Stack>
          {isEdit.edit === true || window.location.pathname === "/users/new" ? (
            <Grid container spacing={3} pt={3}>
              <Grid item xs={12} md={4}>
                {label("First Name")}
                <Controller
                  name="firstName"
                  control={control}
                  rules={{
                    required: "First Name is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      type="text"
                      autoComplete="off"
                      {...register("firstName", {
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: "Please Enter Valid Character",
                        },
                      })}
                      variant="outlined"
                      disabled={isEdit.disabled}
                      error={errors?.firstName ? true : false}
                      helperText={errors?.firstName ? errors.firstName.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {label("Last Name")}
                <Controller
                  name="lastName"
                  control={control}
                  rules={{
                    required: "Last Name is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      {...field}
                      fullWidth
                      required
                      type="text"
                      autoComplete="off"
                      {...register("lastName", {
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: "Please Enter Valid Character",
                        },
                      })}
                      variant="outlined"
                      disabled={isEdit.disabled}
                      error={errors?.lastName ? true : false}
                      helperText={errors?.lastName ? errors.lastName.message : null}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                {label("Email Address")}
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Email is required!",
                  }}
                  render={({ field }) => (
                    <MDInput
                      disabled={isEdit.disabled}
                      {...field}
                      fullWidth
                      required
                      {...register("email", {
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Entered value does not match email format",
                        },
                      })}
                      autoComplete="off"
                      variant="outlined"
                      error={errors?.email ? true : false}
                      helperText={errors?.email ? errors.email.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {label("Role")}
                <Controller
                  name="roleId"
                  control={control}
                  rules={{
                    required: "Role is required!",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      required
                      error={errors?.roleId ? true : false}
                      size="small"
                      sx={{
                        label: {
                          color: `${formDetailsColor?.primary}`,
                          fontWeight: "400",
                        },
                      }}
                    >
                      <Select
                        value={value}
                        disabled={isEdit.disabled}
                        onChange={onChange}
                        sx={selectStyle}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                      >
                        {roles.map((role) => (
                          <MenuItem value={role.id}>{role.name}</MenuItem>
                        ))}
                      </Select>
                      {errors?.roleId && (
                        <FormHelperText>
                          {errors?.roleId ? errors.roleId.message : null}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {label("Status")}
                <Controller
                  name="userStatusId"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{
                        label: {
                          color: `${formDetailsColor?.primary}`,
                          fontWeight: "400",
                        },
                      }}
                    >
                      <Select
                        value={value}
                        disabled={isEdit.disabled || window.location.pathname === "/users/new"}
                        onChange={onChange}
                        sx={selectStyle}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                      >
                        {status.map((status) => {
                          if (status.id !== 1) {
                            return <MenuItem value={status.id}>{status.name}</MenuItem>;
                          }
                        })}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                {label("Description", true)}
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <MDInput
                      multiline
                      placeholder="Type in here…"
                      rows={4}
                      disabled={isEdit.disabled}
                      {...field}
                      fullWidth
                      required
                      {...register("description")}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={1.8}>
              <Grid item xs={12} md={4}>
                {label("First Name")}
                <Typography fontSize="14px" fontWeight="600">
                  {watch("firstName")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                {label("Last Name")}
                <Typography fontSize="14px" fontWeight="600">
                  {watch("lastName")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                {label("Email address")}
                <Typography fontSize="14px" fontWeight="600">
                  {watch("email")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                {label("Role")}
                <Typography fontSize="14px" fontWeight="600">
                  {roles?.find((value) => Number(value.id) === Number(watch("roleId")))?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                {label("Status")}
                <Typography fontSize="14px" fontWeight="600">
                  {
                    status?.find((value) => Number(value.id) === Number(watch("userStatusId")))
                      ?.name
                  }
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                {label("Description", true)}
                <Typography fontSize="14px" fontWeight="600">
                  {watch("description")}
                </Typography>
              </Grid>
            </Grid>
          )}
        </MDBox>
      </form>
    </>
  );
}

export default UserBasicDetails;
