import { EditOutlined } from "@mui/icons-material";
import { Checkbox, FormControlLabel, FormGroup, Stack, Typography } from "@mui/material";
import colors from "assets/theme/base/colors";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TLinkApi from "services/tlink.api";

const AllowBuyers = ({ buyerList, setIsLoading, selectedClient, setSelectedClient }) => {
  const { grey } = colors;
  const { id } = useParams();
  const [addData, setAddData] = useState(true);
  const [isEdit, setIsEdit] = useState({
    disabled: false,
    edit: false,
  });
  const [blockList, setBlockList] = useState([]);
  let { enqueueSnackbar } = useSnackbar();

    const GetBlockBuyers = async () => {
      setIsLoading(true);
      try {
        let data = await TLinkApi.get(`vendors/${id}/clients/${selectedClient}/blocked/buyers`);
        if (data?.data && data?.data.length > 0) {
          setBlockList(data.data.map((val) => Number(val.buyerId)));
          setAddData(false);
          setIsEdit({
            disabled: true,
            edit: false,
          });
        } else {
          setAddData(true);
          setIsEdit({
            disabled: false,
            edit: false,
          });
        }

        setIsLoading(false);
      } catch (e) {
        console.log(e);
        enqueueSnackbar(e?.response.data.message, {
          variant: "error",
        });
        setIsLoading(false);
      }
    };

    useEffect(() => {
      if (selectedClient) {
        GetBlockBuyers();
      }
    }, [selectedClient]);

    const save = async () => {
      try {
        if (addData) {
          let payload = {
            vendorId: id,
            clientId: selectedClient,
            buyerIds: blockList,
          };
          let result = await TLinkApi.post(`/vendors/client/buyers/block`, payload);
          enqueueSnackbar(result?.message, {
            variant: "success",
          });
        } else {
          let payload = {
            vendorId: id,
            clientId: selectedClient,
            buyerIds: blockList,
          };
          let result = await TLinkApi.post(`/vendors/client/buyers/block`, payload);
          enqueueSnackbar(result?.message, {
            variant: "success",
          });
        }
        await GetBlockBuyers();
        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
        enqueueSnackbar(e?.response.data.message, {
          variant: "error",
        });
      }
    };

    useEffect(() => {
      setBlockList([]);
    }, [selectedClient]);

  return (
    <MDBox
      sx={{
        height: "calc(100vh - 300px)",
        overflowY: "auto",
        paddingInline: "1rem",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        position="sticky"
        top="0"
        pb="0.5rem"
        sx={{ borderBottom: `1px solid ${grey[400]}`, background: "white", zIndex: "2" }}
      >
        <Typography variant="v6" fontWeight="600">
          Client Buyers Blocked
        </Typography>

        {isEdit.disabled ? (
          <MDButton
            size="small"
            variant="contained"
            color="secondary"
            sx={{ display: !selectedClient ? "none" : "" }}
            startIcon={<EditOutlined />}
            onClick={() => {
              setIsEdit({
                disabled: false,
                edit: true,
              });
            }}
          >
            Edit
          </MDButton>
        ) : (
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{ display: !selectedClient ? "none" : "" }}
          >
            <MDButton
              variant="contained"
              color="secondary"
              size="small"
                onClick={() => {
                  if (addData) {
                    setBlockList([]);
                    setSelectedClient(null);
                  } else {
                    GetBlockBuyers();
                  }
                }}
            >
              &nbsp;cancel
            </MDButton>
            <MDButton
              variant="gradient"
              color="info"
                disabled={blockList.length <= 0}
              size="small"
                onClick={() => {
                  save();
                }}
            >
              Save
            </MDButton>
          </Stack>
        )}
      </Stack>
      {selectedClient ? (
        <FormGroup>
          {isEdit.edit || addData ? (
            <MDBox
              sx={{
                padding: isEdit.disabled ? "1rem 0.5rem" : "0.5rem",
                borderBottom: `1px solid ${grey[400]}`,
                display: buyerList?.length === 0 ? "none" : "",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={blockList.length !== 0 && buyerList?.length === blockList.length}
                    color="secondary"
                    onChange={(e) => {
                      let payload = [];
                      if (e.target.checked) {
                        payload = buyerList?.map((val) => Number(val.id));
                      } else {
                        payload = [];
                      }
                      setBlockList(payload);
                    }}
                  />
                }
                label={"Select All"}
              />
            </MDBox>
          ) : (
            ""
          )}
          {buyerList?.length > 0 ? (
            buyerList?.filter((val) => 
                    (isEdit.disabled && blockList.includes(Number(val.id))) || isEdit.edit || addData)
                .map((buyer, i) => (
              <MDBox
                sx={{
                  padding: isEdit.disabled ? "1rem 0.5rem" : "0.5rem",
                  borderBottom: `1px solid ${grey[400]}`,
                }}
                key={i}
              >
                {isEdit.edit || addData ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={blockList.includes(Number(buyer.id))}
                        color="secondary"
                          onChange={(e) => {
                            let payload = [...blockList];
                            if (e.target.checked) {
                              payload.push(Number(buyer.id));
                            } else {
                              let data = payload.filter((d) => d !== Number(buyer.id));
                              payload = data;
                            }
                            setBlockList(payload);
                          }}
                      />
                    }
                    label={buyer.name}
                  />
                ) : (
                  <Typography fontSize="0.875rem" fontWeight="700">
                    {buyer.name}
                  </Typography>
                )}
              </MDBox>
            ))
          ) : (
            <Stack direction="row" alignItems="center" justifyContent="center" mt={2}>
              <Typography fontSize="15px" color="dark" fontWeight="500" textAlign="center">
                No buyers here !
              </Typography>
            </Stack>
          )}
        </FormGroup>
      ) : (
        <Stack direction="row" alignItems="center" justifyContent="center" mt={2}>
          <Typography fontSize="15px" color="dark" fontWeight="500" textAlign="center">
            Please select client to see its buyers
          </Typography>
        </Stack>
      )}
    </MDBox>
  );
};

export default AllowBuyers;
