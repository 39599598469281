import { useEffect, useState } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import SidenavCollapse from "comps/Sidenav/SidenavCollapse";
import SidenavRoot from "comps/Sidenav/SidenavRoot";
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import menu from "assets/images/menu.svg";
import colors from "assets/theme/base/colors";
import "../../global.css";
import signInLogo from "../../assets/logo/sign_in_logo.png";
import { Tooltip } from "@mui/material";

function Sidenav({ color, width, setWidth, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const [expand, setExpand] = useState("");
  const location = useLocation();
  const collapseName = window.location.pathname.split("/")[1];
  const { info } = colors;

  const [sideNavMin, setSideNavMin] = useState(false);
  const [child, setChild] = useState(null);
  const navigate = useNavigate();

  function updateSize() {
    if (window.innerWidth < 1200) {
      setSideNavMin(true);
    } else if (window.innerWidth > 1200) {
      setSideNavMin(false);
    }
  }

  window.addEventListener("resize", updateSize);

  useEffect(() => {
    updateSize();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth > 1200);
    }
    window.addEventListener("resize", handleMiniSidenav);
    handleMiniSidenav();
    return () => window.removeEventListener("resize", handleMiniSidenav);
    // eslint-disable-next-line
  }, [dispatch, location]);

  useEffect(() => {
    if (collapseName === "surveys" || collapseName === "projects") {
      setChild("Projects");
      setExpand("Projects");
    } else if (
      collapseName === "vendors" ||
      collapseName === "clients" ||
      collapseName === "priority" ||
      collapseName === "qualification" ||
      collapseName === "users" ||
      collapseName === "permissions"
    ) {
      setChild("Admin");
      setExpand("Admin");
    } else if (collapseName === "permissions" || collapseName === "users") {
      setChild("Users");
      setExpand("Users");
    } else {
      setChild("");
      setExpand("");
    }
  }, [collapseName]);

  const renderRoutes = routes.map(
    ({ type, name, icon, noCollapse, key, href, route, collapse }) => {
      let returnValue;
      if (type === "collapse") {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={name}
              root={false}
              sideNavMin={sideNavMin}
              icon={icon}
              width={width}
              active={key === collapseName}
              noCollapse={noCollapse}
              onClick={() => {
                setExpand("");
                // setWidth(250);
                setChild(false);
              }}
            />
          </Link>
        ) : (
          <NavLink key={key} to={route}>
            <SidenavCollapse
              name={name}
              width={width}
              sideNavMin={sideNavMin}
              icon={icon}
              active={key === collapseName}
              onClick={() => {
                setExpand("");
                setChild(false);
              }}
            />
          </NavLink>
        );
      } else if (type === "title") {
        returnValue = (
          <div key={key}>
            <SidenavCollapse
              name={name}
              icon={icon}
              sideNavMin={sideNavMin}
              root={true}
              width={width}
              dropDownIcon={expand}
              child={child}
              style={{
                backgroundColor: expand === name ? "#e6e6e6" : "",
                transition: "color 0.3s",
                color: expand === name && info.main,
              }}
              onClick={() => {
                if (expand === "") {
                  setExpand(name);
                  navigate(`${collapse[0].route}`);
                } else if (expand !== name) {
                  setExpand(name);
                  navigate(`${collapse[0].route}`);
                } else {
                  setExpand("");
                }
              }}
            />
            <MDBox
              py={expand === name ? 0.5 : ""}
              px={0.25}
              style={{
                height: expand === name ? collapse.length * 48 : "0px",
                overflow: "hidden",
                transition: "height 0.3s",
                backgroundColor: !sideNavMin ? "#F0F0F0" : "",
              }}
            >
              {collapse?.map((value, i) => {
                return (
                  <NavLink to={`${value.route}`} key={i}>
                    <SidenavCollapse
                      key={collapseName}
                      sideNavMin={sideNavMin}
                      name={value.name}
                      width={width}
                      icon={<Icon fontSize="small">{value.icon}</Icon>}
                      active={collapseName === value.key}
                      style={{
                        paddingLeft: sideNavMin ? "20px" : "30px", 
                        backgroundColor: sideNavMin ? "inherit" : "transparent",
                        transition: "background-color 0.3s ease", 
                      }}
                      onClick={() => {
                        setChild(name);
                        // setWidth(250);
                      }}
                    />
                  </NavLink>
                );
              })}
            </MDBox>
          </div>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode, width, setWidth }}
    >
      <MDBox pt={2} pb={1}>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          style={{
            flexDirection: "column",
            alignItems: "left",
            display: "flex",
            borderBottom: "1px solid #E4E4E4",
          }}
          pb={0.9}
        >
          <MDBox component="img" src={signInLogo} px={3} width="75px" height="24px" />
          <MDBox style={{ fontSize: "10px" }} px={2} color="info">
            Symmppl&trade;
          </MDBox>
        </MDBox>
      </MDBox>
      <List sx={{ padding: "0.5rem" }}>{renderRoutes}</List>
      <MDBox display="flex" alignItems="center" justifyContent="center" mt="auto" mb={2.5}>
        <MDBox
          onClick={() => {
            if (width === 72) {
              setWidth(250);
            } else {
              setWidth(72);
            }
          }}
          color={"black"}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="row"
          fontSize="15px"
          cursor="pointer"
        >
          <Tooltip title="Expand Menu" placement="right">
            <MDBox
              component="img"
              height="20px"
              width="20px"
              sx={{
                display: {
                  md: "none",
                  lg: "unset",
                },
              }}
              src={menu}
            />
          </Tooltip>
          <span
            style={{ display: width === 72 ? "none" : "", marginLeft: "10px", cursor: "pointer" }}
          >
            Collapse Menu
          </span>
          &nbsp;
        </MDBox>
      </MDBox>
    </SidenavRoot>
  );
}

Sidenav.defaultProps = {
  color: "info",
};

Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "headingColor",
    "error",
    "dark",
    "textColor",
  ]),
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
