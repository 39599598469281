import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MDInput from "components/MDInput";
import {
  Backdrop,
  CircularProgress,
  FormControlLabel,
  Grid,
  Icon,
  Stack,
  Switch,
  FormControl,
  Select,
  FormHelperText,
  MenuItem,
  Tooltip,
  InputLabel,
} from "@mui/material";
import MDButton from "components/MDButton";
import TLinkApi from "services/tlink.api";
import { useSnackbar } from "notistack";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Controller, useForm } from "react-hook-form";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useParams } from "react-router-dom";
import GreenTick from "assets/images/GreenTick.svg";
import BlueTick from "assets/images/BlueTick.svg";
import Tick from "assets/images/Tick.svg";
import TuneIcon from "@mui/icons-material/Tune";
import RepeatOutlinedIcon from "@mui/icons-material/RepeatOutlined";
import SplitscreenOutlinedIcon from "@mui/icons-material/SplitscreenOutlined";
import IosShareIcon from "@mui/icons-material/IosShare";
import colors from "assets/theme/base/colors";
import moment from "moment/moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import csvDownload from "json-to-csv-export";

function Row(props) {
  const { row, setIsLoading, getTableData, survey, getSupplierData } = props;
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = useState(false);
  let { enqueueSnackbar } = useSnackbar();
  const [enabled, setEnabled] = useState(false);
  const { id } = useParams();
  const [completeStepsId, setCompleteStepsId] = useState([]);
  let payload = [...completeStepsId];

  const [tab, setTab] = React.useState("1");

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      vendorCompleted: "",
      vendorQuotaFull: "",
      vendorTerminate: "",
      vendorSecurity: "",
      s2sCompleted: "",
      s2sQuotaFull: "",
      s2sTerminate: "",
      s2sSecurity: "",
      supplierMode: 2,
      autopunch: false,
      s2sEnabled: false,
      isActive: false,
    },
  });
  let s2sEnabled = watch("s2sEnabled");
  let autopunch = watch("autopunch");
  let vendorCompleted = watch("vendorCompleted");

  const save = async (data) => {
    let payload = {
      vendorTerminate: data.vendorTerminate,
      vendorCompleted: data.vendorCompleted,
      vendorSecurity: data.vendorSecurity,
      vendorQuotaFull: data.vendorQuotaFull,
      s2sCompleted: data.s2sEnabled ? data.s2sCompleted : "",
      s2sQuotaFull: data.s2sEnabled ? data.s2sQuotaFull : "",
      s2sSecurity: data.s2sEnabled ? data.s2sSecurity : "",
      s2sTerminate: data.s2sEnabled ? data.s2sTerminate : "",
      allocation: data.allocation,
      vcpi: data.vcpi,
      supplierModeId: data.supplierMode,
      autopunch: data.autopunch,
      s2sEnabled: data.s2sEnabled,
      id: id,
    };
    try {
      let result = await TLinkApi.put(`survey/supplier/${row.surveySupplierId}`, payload);
      setIsLoading(false);
      setEdit(false);
      enqueueSnackbar(result?.message, {
        variant: "success",
      });
      props.getTableData({});
      getSupplierData();
    } catch (e) {
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
      setIsLoading(false);
    }
  };

  const updateVendor = async (supplierId, active) => {
    let payload = {
      isActive: active,
    };
    setIsLoading(true);
    try {
      await TLinkApi.put(`/survey/${id}/supplier/${supplierId}`, payload);
      setIsLoading(false);
      getTableData();
      getSupplierData();
      enqueueSnackbar("Vendor Successfully Updated", {
        variant: "success",
      });
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      enqueueSnackbar(e?.response.data.message, {
        variant: "error",
      });
    }
  };

  React.useEffect(() => {
    if (Object.keys(row).length > 0) {
      setValue("vendorTerminate", row.vendorTerminate);
      setValue("vendorCompleted", row.vendorCompleted);
      setValue("vendorSecurity", row.vendorSecurity);
      setValue("vendorQuotaFull", row.vendorQuotaFull);
      setValue("s2sQuotaFull", row.s2sQuotaFull);
      setValue("s2sCompleted", row.s2sCompleted);
      setValue("s2sSecurity", row.s2sSecurity);
      setValue("s2sTerminate", row.s2sTerminate);
      setValue("allocation", row.allocation);
      setValue("vcpi", row.vcpi);
      setValue("s2sEnabled", row.s2sEnabled);
      setValue("supplierMode", row.supplierModeId ? row.supplierModeId : 2);
      setValue("autopunch", row.autopunch);
      setValue("isActive", row.isActive);
      setEnabled(row.isActive);
      if (
        row.link &&
        row.testLink &&
        row.vcpi &&
        row.allocation
        // row.supplierModeId &&
        // row.autopunch
      ) {
        payload.push(1);
      }
      if (row.vendorCompleted && row.vendorTerminate) {
        payload.push(2);
      }
      if (row.s2sCompleted && row.s2sTerminate && row.s2sSecurity && row.s2sQuotaFull) {
        payload.push(3);
      }
      if (row.s2sEnabled === false) {
        payload.push(3);
      }
      setCompleteStepsId(payload);
    }
  }, [row]);

  React.useEffect(() => {}, [completeStepsId]);

  function copy(text) {
    navigator.clipboard.writeText(text);
    enqueueSnackbar("URL copy Successfully!", {
      variant: "success",
    });
  }

  let selectStyle = {
    height: "36.5px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell align="left">{row.supplierName}</TableCell>
        <TableCell scope="row" align="center">
          {row.supplierId}
        </TableCell>
        <TableCell align="center">{parseFloat(row.vcpi).toFixed(2)}</TableCell>
        <TableCell align="center">{row.allocation}</TableCell>
        <TableCell align="center">{(survey?.sampleRequired * row.allocation) / 100}</TableCell>
        <TableCell align="center">{row.starts}</TableCell>
        <TableCell align="center">{row.completes}</TableCell>
        <TableCell align="center">{parseFloat(row.conversion).toFixed(2)}</TableCell>
        <TableCell align="center">{parseFloat(row.epc).toFixed(2)}</TableCell>
        <TableCell align="center">{row.isApi ? "Programmatic" : "Manual"}</TableCell>
        <TableCell align="right">
          <Switch
            size="small"
            color="secondary"
            checked={enabled}
            onChange={(e, value) => {
              setEnabled(!enabled);
              updateVendor(row.supplierId, e.target.checked);
            }}
          />
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 2 }}>
              <form onSubmit={handleSubmit(save)} noValidate>
                <Table
                  size="small"
                  aria-label="purchases"
                  sx={{
                    "& .MuiTableCell-root": {
                      borderBottom: "none",
                    },
                  }}
                >
                  <TableBody>
                    <Grid item xs={12} md={4}>
                      <Box>
                        <TabContext value={tab}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                              <Tab
                                icon={
                                  <img
                                    src={
                                      completeStepsId.includes(1)
                                        ? GreenTick
                                        : tab === "1"
                                        ? BlueTick
                                        : Tick
                                    }
                                  />
                                }
                                label={
                                  <MDTypography
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontSize: "18px",
                                    }}
                                  >
                                    <TuneIcon sx={{ color: "#0e60e6" }} />
                                    &nbsp;Configuration
                                  </MDTypography>
                                }
                                iconPosition="end"
                                value="1"
                              />
                              <Tab
                                icon={
                                  <img
                                    src={
                                      completeStepsId.includes(2)
                                        ? GreenTick
                                        : tab === "2"
                                        ? BlueTick
                                        : Tick
                                    }
                                  />
                                }
                                label={
                                  <MDTypography
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontSize: "18px",
                                    }}
                                  >
                                    <RepeatOutlinedIcon sx={{ color: "#0e60e6" }} />
                                    &nbsp;Redirect URL(s)
                                  </MDTypography>
                                }
                                iconPosition="end"
                                value="2"
                              />
                              <Tab
                                icon={
                                  <img
                                    src={
                                      completeStepsId.includes(3)
                                        ? GreenTick
                                        : tab === "3"
                                        ? BlueTick
                                        : Tick
                                    }
                                  />
                                }
                                label={
                                  <MDTypography
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontSize: "18px",
                                    }}
                                  >
                                    <SplitscreenOutlinedIcon sx={{ color: "#0e60e6" }} />
                                    &nbsp;S2S URL(s)
                                  </MDTypography>
                                }
                                iconPosition="end"
                                value="3"
                              />
                              <Grid
                                container
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-end"
                              >
                                {!edit ? (
                                  <Tooltip title="Edit" placement="top">
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        setEdit(true);
                                      }}
                                    >
                                      <Icon color="dark">border_color_icon</Icon>
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <MDBox display="flex" alignItems="center" gap="10px">
                                    <MDButton
                                      color="secondary"
                                      variant="contained"
                                      size="small"
                                      onClick={() => {
                                        getTableData();
                                        getSupplierData();
                                        setEdit(false);
                                        setOpen(false);
                                      }}
                                      disabled={!edit}
                                    >
                                      Cancel
                                    </MDButton>
                                    <MDButton
                                      color="info"
                                      variant="contained"
                                      size="small"
                                      type="submit"
                                      disabled={!edit}
                                    >
                                      Save
                                    </MDButton>
                                  </MDBox>
                                )}
                              </Grid>
                            </TabList>
                          </Box>

                          <TabPanel value="1">
                            <MDBox sx={{ borderRadius: "10px" }}>
                              <MDTypography variant="h6" mb={1}>
                                Configuration
                              </MDTypography>
                              <MDTypography sx={{ fontSize: "12px" }}>Live URL</MDTypography>
                              <MDBox
                                bgColor="#F5F5F5"
                                px={1}
                                sx={{
                                  border: "1px solid #D9D9D9",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  flexDirection: "row",
                                  fontSize: "14px",
                                }}
                              >
                                <MDBox
                                  sx={{
                                    width: "80%",
                                    overflow: "hidden",
                                    display: "inline-block",
                                    textDecoration: "none",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {row.link}
                                </MDBox>
                                <IconButton
                                  onClick={() => {
                                    copy(row.link);
                                  }}
                                >
                                  <ContentCopyTwoToneIcon fontSize="small" />
                                </IconButton>
                              </MDBox>
                              <MDTypography sx={{ fontSize: "12px", marginTop: "1rem" }}>
                                Test URL
                              </MDTypography>

                              <MDBox
                                bgColor="#F5F5F5"
                                px={1}
                                sx={{
                                  border: "1px solid #D9D9D9",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  flexDirection: "row",
                                }}
                              >
                                <MDBox
                                  sx={{
                                    width: "80%",
                                    overflow: "hidden",
                                    display: "inline-block",
                                    textDecoration: "none",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {row.testLink}
                                </MDBox>
                                <IconButton
                                  onClick={() => {
                                    copy(row.testLink);
                                  }}
                                >
                                  <ContentCopyTwoToneIcon fontSize="small" />
                                </IconButton>
                              </MDBox>
                            </MDBox>
                            <MDBox pt={2}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                  <MDTypography
                                    sx={{ color: "black", fontSize: "12px", fontWeight: "500" }}
                                    mb={0.5}
                                  >
                                    Vcpi
                                  </MDTypography>
                                  <Controller
                                    name="vcpi"
                                    control={control}
                                    rules={{
                                      required: "Vcpi is Required!",
                                    }}
                                    render={({ field }) => (
                                      <MDInput
                                        {...field}
                                        fullWidth
                                        {...register("vcpi", {
                                          min: 0,
                                        })}
                                        type="number"
                                        disabled={!edit}
                                        autoComplete="off"
                                        variant="outlined"
                                        placeholder="Vcpi"
                                        error={errors?.vcpi ? true : false}
                                        helperText={
                                          errors?.vcpi && errors.vcpi.message !== ""
                                            ? errors.vcpi.message
                                            : errors?.vcpi?.type === "min"
                                            ? "vcpi should not be less then 0"
                                            : errors?.vcpi?.type === "max"
                                            ? "vcpi should not be more then 100"
                                            : null
                                        }
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <MDTypography
                                    sx={{ color: "black", fontSize: "12px", fontWeight: "500" }}
                                    mb={0.5}
                                  >
                                    Allocation (%)
                                  </MDTypography>
                                  <Controller
                                    name="allocation"
                                    control={control}
                                    rules={{
                                      required: "Allocation is Required!",
                                    }}
                                    render={({ field }) => (
                                      <MDInput
                                        {...field}
                                        fullWidth
                                        InputProps={{ inputProps: { min: 0, max: 100 } }}
                                        type="number"
                                        {...register("allocation", {
                                          min: 0,
                                          max: 100,
                                        })}
                                        disabled={!edit}
                                        autoComplete="off"
                                        variant="outlined"
                                        placeholder="Allocation %"
                                        error={errors?.allocation ? true : false}
                                        helperText={
                                          errors?.allocation && errors.allocation.message !== ""
                                            ? errors.allocation.message
                                            : errors?.allocation?.type === "min"
                                            ? "Allocation should not be less then 0!"
                                            : errors?.allocation?.type === "max"
                                            ? "Allocation should not be more then 100!"
                                            : null
                                        }
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <MDTypography
                                    sx={{ color: "black", fontSize: "12px", fontWeight: "500" }}
                                    mb={0.5}
                                  >
                                    Supplier Mode
                                  </MDTypography>
                                  <Controller
                                    name="supplierMode"
                                    control={control}
                                    rules={{
                                      required: "Supplier Mode Id is required!",
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                      <FormControl
                                        fullWidth
                                        required
                                        error={errors?.supplierMode ? true : false}
                                        sx={{
                                          label: {
                                            color: "rgba(0, 0, 0, 0.25)",
                                            fontWeight: "400",
                                          },
                                        }}
                                        size="small"
                                      >
                                        <Select
                                          value={value}
                                          onChange={onChange}
                                          placeholder="Supplier Mode"
                                          sx={selectStyle}
                                          disabled={!edit}
                                          MenuProps={{
                                            style: {
                                              width: "150px",
                                              height: "250px",
                                            },
                                          }}
                                        >
                                          {props.supplierModeList?.map((value, i) => {
                                            return (
                                              <MenuItem value={value.id} key={i}>
                                                {value.name}
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                        {errors?.supplierMode && (
                                          <FormHelperText>
                                            {errors?.supplierMode
                                              ? errors.supplierMode.message
                                              : null}
                                          </FormHelperText>
                                        )}
                                      </FormControl>
                                    )}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  display={"flex"}
                                  alignItems={"center"}
                                  mt={"22px"}
                                >
                                  <Tooltip title="Track Demographic Details" placement="top">
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={autopunch}
                                          disabled={!edit}
                                          onChange={(e, value) => {
                                            setValue("autopunch", value);
                                          }}
                                        />
                                      }
                                      label={
                                        <MDTypography
                                          sx={{
                                            color: "black",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                          }}
                                          mb={0.5}
                                        >
                                          Autopunch
                                        </MDTypography>
                                      }
                                    />
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </MDBox>
                          </TabPanel>

                          <TabPanel value="2">
                            <MDTypography variant="h6" mb={1}>
                              Redirect URL(s)
                            </MDTypography>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={12}>
                                <MDTypography
                                  sx={{ color: "black", fontSize: "12px", fontWeight: "500" }}
                                  mb={0.5}
                                >
                                  Complete*
                                </MDTypography>
                                <Controller
                                  name="vendorCompleted"
                                  control={control}
                                  rules={{
                                    required: `Complete is required!`,
                                  }}
                                  render={({ field }) => (
                                    <MDInput
                                      {...field}
                                      fullWidth
                                      disabled={!edit}
                                      autoComplete="off"
                                      variant="outlined"
                                      placeholder="S2S QuotaFull"
                                      {...register("vendorCompleted", {
                                        pattern: {
                                          value:
                                            //eslint-disable-next-line
                                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                          message: "invalid URl ",
                                        },
                                      })}
                                      error={errors?.vendorCompleted ? true : false}
                                      helperText={
                                        errors?.vendorCompleted
                                          ? errors.vendorCompleted.message
                                          : null
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <MDTypography
                                  sx={{ color: "black", fontSize: "12px", fontWeight: "500" }}
                                  mb={0.5}
                                >
                                  Terminate*
                                </MDTypography>
                                <Controller
                                  name="vendorTerminate"
                                  control={control}
                                  rules={{
                                    required: "Terminate is Required!",
                                  }}
                                  render={({ field }) => (
                                    <MDInput
                                      {...field}
                                      fullWidth
                                      disabled={!edit}
                                      autoComplete="off"
                                      variant="outlined"
                                      placeholder="S2S QuotaFull"
                                      {...register("vendorTerminate", {
                                        validate: (value) => {
                                          if (vendorCompleted === value) {
                                            return "Terminate and Complete can't be same!";
                                          }
                                          return true;
                                        },
                                        pattern: {
                                          value:
                                            //eslint-disable-next-line
                                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                          message: "invalid URl ",
                                        },
                                      })}
                                      error={errors?.vendorTerminate ? true : false}
                                      helperText={
                                        errors?.vendorTerminate
                                          ? errors.vendorTerminate.message
                                          : null
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <MDTypography
                                  sx={{ color: "black", fontSize: "12px", fontWeight: "500" }}
                                  mb={0.5}
                                >
                                  Security Terminate*
                                </MDTypography>
                                <Controller
                                  name="vendorSecurity"
                                  control={control}
                                  rules={{
                                    required: "Security Terminate is required!",
                                  }}
                                  render={({ field }) => (
                                    <MDInput
                                      {...field}
                                      fullWidth
                                      disabled={!edit}
                                      autoComplete="off"
                                      variant="outlined"
                                      placeholder="S2S QuotaFull"
                                      {...register("vendorSecurity", {
                                        validate: (value) => {
                                          if (vendorCompleted === value) {
                                            return "Security Terminate and Complete can't be same!";
                                          }
                                          return true;
                                        },
                                        pattern: {
                                          value:
                                            //eslint-disable-next-line
                                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                          message: "invalid URl ",
                                        },
                                      })}
                                      error={errors?.vendorSecurity ? true : false}
                                      helperText={
                                        errors?.vendorSecurity
                                          ? errors.vendorSecurity.message
                                          : null
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <MDTypography
                                  sx={{ color: "black", fontSize: "12px", fontWeight: "500" }}
                                  mb={0.5}
                                >
                                  Overquota*
                                </MDTypography>
                                <Controller
                                  name="vendorQuotaFull"
                                  control={control}
                                  rules={{
                                    required: "Overquota is required!",
                                  }}
                                  render={({ field }) => (
                                    <MDInput
                                      {...field}
                                      fullWidth
                                      disabled={!edit}
                                      autoComplete="off"
                                      variant="outlined"
                                      placeholder="S2S QuotaFull"
                                      {...register("vendorQuotaFull", {
                                        validate: (value) => {
                                          if (vendorCompleted === value) {
                                            return "Overquota and Complete can't be same!";
                                          }
                                          return true;
                                        },
                                        pattern: {
                                          value:
                                            //eslint-disable-next-line
                                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                          message: "invalid URl ",
                                        },
                                      })}
                                      error={errors?.vendorQuotaFull ? true : false}
                                      helperText={
                                        errors?.vendorQuotaFull
                                          ? errors.vendorQuotaFull.message
                                          : null
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </TabPanel>
                          <TabPanel value="3">
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={s2sEnabled}
                                  disabled={!edit}
                                  onChange={(e, value) => {
                                    setValue("s2sEnabled", value);
                                  }}
                                />
                              }
                              label={<MDTypography variant="h6">S2S URLs</MDTypography>}
                            />
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={12}>
                                <MDTypography
                                  sx={{ color: "black", fontSize: "12px", fontWeight: "500" }}
                                  mb={0.5}
                                >
                                  Complete
                                </MDTypography>
                                <Controller
                                  name="s2sCompleted"
                                  control={control}
                                  rules={{
                                    required: s2sEnabled && `S2S Completed is required!`,
                                  }}
                                  render={({ field }) => (
                                    <MDInput
                                      {...field}
                                      fullWidth
                                      disabled={!edit}
                                      autoComplete="off"
                                      variant="outlined"
                                      placeholder="S2S Completed"
                                      {...register("s2sCompleted", {
                                        pattern: {
                                          value:
                                            //eslint-disable-next-line
                                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                          message: "invalid URl ",
                                        },
                                      })}
                                      error={errors?.s2sCompleted ? true : false}
                                      helperText={
                                        errors?.s2sCompleted ? errors.s2sCompleted.message : null
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <MDTypography
                                  sx={{ color: "black", fontSize: "12px", fontWeight: "500" }}
                                  mb={0.5}
                                >
                                  Terminate
                                </MDTypography>
                                <Controller
                                  name="s2sTerminate"
                                  control={control}
                                  rules={{
                                    required: s2sEnabled && "Terminate is Required!",
                                  }}
                                  render={({ field }) => (
                                    <MDInput
                                      {...field}
                                      fullWidth
                                      disabled={!edit}
                                      autoComplete="off"
                                      variant="outlined"
                                      placeholder="S2S Terminate"
                                      {...register("s2sTerminate", {
                                        pattern: {
                                          value:
                                            //eslint-disable-next-line
                                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                          message: "invalid URl ",
                                        },
                                      })}
                                      error={errors?.s2sTerminate ? true : false}
                                      helperText={
                                        errors?.s2sTerminate ? errors.s2sTerminate.message : null
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <MDTypography
                                  sx={{ color: "black", fontSize: "12px", fontWeight: "500" }}
                                  mb={0.5}
                                >
                                  Security Terminate
                                </MDTypography>
                                <Controller
                                  name="s2sSecurity"
                                  control={control}
                                  rules={{
                                    required: s2sEnabled && "Security Terminate is required!",
                                  }}
                                  render={({ field }) => (
                                    <MDInput
                                      {...field}
                                      fullWidth
                                      disabled={!edit}
                                      autoComplete="off"
                                      variant="outlined"
                                      placeholder="S2S Security"
                                      {...register("s2sSecurity", {
                                        pattern: {
                                          value:
                                            //eslint-disable-next-line
                                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                          message: "invalid URl ",
                                        },
                                      })}
                                      error={errors?.s2sSecurity ? true : false}
                                      helperText={
                                        errors?.s2sSecurity ? errors.s2sSecurity.message : null
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <MDTypography
                                  sx={{ color: "black", fontSize: "12px", fontWeight: "500" }}
                                  mb={0.5}
                                >
                                  Overquota
                                </MDTypography>
                                <Controller
                                  name="s2sQuotaFull"
                                  control={control}
                                  rules={{
                                    required: s2sEnabled && "Overquota is required!",
                                  }}
                                  render={({ field }) => (
                                    <MDInput
                                      {...field}
                                      fullWidth
                                      disabled={!edit}
                                      autoComplete="off"
                                      variant="outlined"
                                      placeholder="S2S QuotaFull"
                                      {...register("s2sQuotaFull", {
                                        pattern: {
                                          value:
                                            //eslint-disable-next-line
                                            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                          message: "invalid URl ",
                                        },
                                      })}
                                      error={errors?.s2sQuotaFull ? true : false}
                                      helperText={
                                        errors?.s2sQuotaFull ? errors.s2sQuotaFull.message : null
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </Grid>
                  </TableBody>
                </Table>
              </form>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable({
  supplierTable,
  vendorsMap,
  getTableData,
  setOpen,
  survey,
  setEditOpen,
  open,
  editOpen,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [supplierModeList, setSupplierModeList] = useState([]);
  const { info } = colors;
  const [supplierData, setSupplierData] = useState([]);
  const { id } = useParams();
  const [suppliers, setSuppliers] = useState(-1);
  const [supplierMode, setSupplierMode] = useState(-1);
  const [supplierList, setSupplierList] = useState([]);

  const getSupplierList = async () => {
    try {
      let data = await TLinkApi.post(`/survey/${id}/supplier/?supplierId=-1&supplierModeId=-1`, {
        endDate: "",
        startDate: "",
      });
      setSupplierList(
        data?.map((supplier) => {
          return { supplierId: supplier.supplierId, supplierName: supplier.supplierName };
        })
      );
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  let getSupplierModeData = async () => {
    setIsLoading(true);
    try {
      let result = await TLinkApi.get(`/masters/suppliermode`);
      setSupplierModeList(result.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getSupplierModeData();
    getSupplierList();
  }, []);

  // const [timeline, setTimeLine] = useState({
  //   endDate: moment().subtract(0, "days").endOf("day").format("YYYY-MM-DD"),
  //   startDate: moment().subtract(1, "days").startOf("day").format("YYYY-MM-DD"),
  // });

  const getSupplierData = async () => {
    setIsLoading(true);
    try {
      let data = await TLinkApi.post(
        `/survey/${id}/supplier/?supplierId=${suppliers}&supplierModeId=${supplierMode}`
      );
      setSupplierData(data);
      console.log(data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSupplierData();
  }, [id, suppliers, supplierMode, open, editOpen]);

  // const ranges = {
  //   Today: [moment().subtract(1, "days").startOf("day"), moment().subtract(0, "days").endOf("day")],
  //   "Last Week": [
  //     moment().subtract(7, "days").startOf("day"),
  //     moment().subtract(0, "days").endOf("day"),
  //   ],
  //   "Last Month": [
  //     moment().subtract(30, "days").startOf("day"),
  //     moment().subtract(0, "days").endOf("day"),
  //   ],
  //   "Last Year": [
  //     moment().subtract(365, "days").startOf("day"),
  //     moment().subtract(0, "days").endOf("day"),
  //   ],
  // };

  // useEffect(() => {
  //   if (Object.keys(timeline)?.length > 0) {
  //     getSupplierData();
  //   }
  // }, [timeline]);

  const formatCash = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(2) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(2) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(2) + "T";
  };

  let selectStyle = {
    height: "36.5px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
    "& :hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #D9D9D9",
      borderRadius: "0px",
    },
  };

  return (
    <MDBox>
      <Grid Container mt={-1.5} ml={-1}>
        <Grid item xs={12}>
          <MDTypography color="black" ml="1%">
            Suppliers
          </MDTypography>
        </Grid>
      </Grid>
      <Stack direction="row" alignItems="center" justifyContent="space-between" marginBottom="1rem">
        <Stack direction="row" alignItems="center" spacing={1}>
          <Grid container alignItems="center" spacing={1} sx={{ width: "700px" }} ml={-1}>
            <Grid item xs={6}>
              <FormControl fullWidth size="small">
                <InputLabel>Suppliers</InputLabel>
                <Select
                  value={suppliers}
                  label="Suppliers"
                  sx={selectStyle}
                  onChange={(e) => {
                    setSuppliers(e.target.value);
                  }}
                >
                  <MenuItem value={-1}>All</MenuItem>
                  {supplierList?.map((supplier) => (
                    <MenuItem key={supplier.supplierId} value={supplier.supplierId}>
                      {supplier.supplierName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth size="small">
                <InputLabel>Supplier Mode</InputLabel>
                <Select
                  value={supplierMode}
                  label="Supplier Mode"
                  sx={selectStyle}
                  onChange={(e) => {
                    setSupplierMode(e.target.value);
                  }}
                >
                  <MenuItem value={-1}>All</MenuItem>
                  {supplierModeList?.map((mode, i) => (
                    <MenuItem value={mode.id} key={i}>
                      {mode.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            {/* <Grid item xs={8}>
              <FormControl fullWidth size="small">
                <DateRangePicker
                  initialSettings={{
                    endDate: moment().subtract(0, "days").endOf("day"),
                    startDate: moment().subtract(1, "days").startOf("day"),
                    ranges: ranges,
                  }}
                  alwaysShowCalendars={false}
                  onCallback={(start, end, label) => {
                    setTimeLine({
                      startDate: moment(start._d).format("YYYY-MM-DD"),
                      endDate: moment(end._d).format("YYYY-MM-DD"),
                    });
                  }}
                >
                  <MDBox position="relative">
                    <MDInput
                      ml={2}
                      color="text"
                      inputProps={{
                        style: {
                          height: "20px",
                          border: "2px solid #D9D9D9",
                        },
                      }}
                      size="large"
                      fullWidth
                      value={
                        moment(timeline.startDate).format("LL") +
                        "  -  " +
                        moment(timeline.endDate).format("LL")
                      }
                    />
                    <KeyboardArrowDownIcon
                      fontSize="small"
                      sx={{
                        fontSize: 20,
                        cursor: "pointer",
                        position: "absolute",
                        right: "10px",
                        top: "12px",
                      }}
                    />
                  </MDBox>
                </DateRangePicker>
              </FormControl>
            </Grid> */}
          </Grid>
        </Stack>

        <MDBox display="flex" alignItems="center" gap="10px">
          <Tooltip title="Update a Supplier" placement="top">
            <IconButton
              size="small"
              onClick={() => {
                setEditOpen(true);
              }}
            >
              <Icon color="dark">border_color_icon</Icon>
            </IconButton>
          </Tooltip>
          <Tooltip title="Add a Supplier" placement="top">
            <MDButton
              variant="contained"
              color="info"
              size="small"
              onClick={() => {
                setOpen(true);
              }}
            >
              + Supplier
            </MDButton>
          </Tooltip>
          <Tooltip title="Export" placement="top">
            <IconButton
              variant="contained"
              color="white"
              sx={{
                backgroundColor: `${info.main} !important`,
                borderRadius: "4px !important",
              }}
              size="small"
              onClick={() => {
                const dataToConvert = {
                  data: supplierData,
                  filename: "Survey Supplier Data",
                  delimiter: ",",
                };
                csvDownload(dataToConvert);
              }}
            >
              <IosShareIcon sx={{ fontSize: "1.1rem", color: "white" }} />
            </IconButton>
          </Tooltip>
        </MDBox>
      </Stack>

      <TableContainer
        sx={{ height: `calc(100vh - 350px)`, borderRadius: "0px !important", boxShadow: "none" }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Table
          stickyHeader
          aria-label="collapsible table"
          sx={{
            overflow: "auto",
            "& th": {
              backgroundColor: `#F5F5F5`,
              color: "black",
              fontSize: "14px",
              fontWeight: 600,
            },
            "& .MuiTableCell-root": {
              fontSize: "14px",
              fontWeight: "400",
            },
            "& .MuiTableCell-root": {
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            },
          }}
        >
          <TableHead style={{ display: "table-header-group" }}>
            <TableRow>
              <TableCell align="left" width="30%">
                Supplier Name
              </TableCell>
              <TableCell align="center">Supplier Id</TableCell>
              <TableCell align="center">Vcpi</TableCell>
              <TableCell align="center">Allocation (%)</TableCell>
              <TableCell align="center">Allocation</TableCell>
              <TableCell align="center">Starts</TableCell>
              <TableCell align="center">Completes</TableCell>
              <TableCell align="center">Conversion</TableCell>
              <TableCell align="center">EPC</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="center">Action</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {supplierData.map((row, i) => (
              <Row
                key={i}
                row={row}
                vendorsMap={vendorsMap}
                getTableData={getTableData}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                survey={survey}
                supplierModeList={supplierModeList}
                getSupplierData={getSupplierData}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MDBox>
  );
}
